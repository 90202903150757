import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { IoIosSearch } from "react-icons/io";
import Partner from "./Partner";

const BannerFive = () => {
  const textSegments = [
    "Connect.",
    "Automate.",
    "Boost Web Traffic.",
    "Maximize ROI.",
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [website, setWebsite] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const currentText = textSegments[currentTextIndex];
    const typingSpeed = 100;

    const timer = setTimeout(() => {
      if (displayedText.length < currentText.length) {
        // Typing the text
        setDisplayedText(currentText.slice(0, displayedText.length + 1));
      } else {
        // Finished typing, move to the next text after a delay
        setTimeout(() => {
          setCurrentTextIndex((prevIndex) =>
            prevIndex === textSegments.length - 1 ? 0 : prevIndex + 1
          );
          setDisplayedText(""); // Reset the text to start typing the next one
        }, 1000); // Delay before moving to the next text
      }
    }, typingSpeed);

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [displayedText, currentTextIndex, textSegments]);

  const handleSearch = () => {
    if (website.trim()) {
      navigate(`/website-preformance-testing-and-monitoring`, {
        state: { urlInput: website },
      });
    }
  };
  return (
    <>
      <div
        className="banner-area bg-relative banner-area-2 pb-0 "
        style={{
          backgroundImage:
            "linear-gradient(to right bottom, #051937, #0a2e50, #0d4569, #0f5d83, #13769b)",
        }}
      >
        <div className="container pt-20">
          <div className="roww d-flex flex-column justify-content-center align-items-centter mx-auto">
            <div className="col-lg-8  col-md-10 col-sm-12 pb-xl-5 align-self-center">
              <div className="banner-inner pe-xl-4 pb-5">
                <h2
                  className="title py-0"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                >
                  {displayedText}
                </h2>
                <h2
                  className="title py-0"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  data-aos-duration="1500"
                  style={{ color: "#F29B8E" }}
                >
                  #FritadoAi
                </h2>

                <p
                  className="content banner-para pe-xl-4"
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="1500"
                >
                  Create undetectable AI-driven content and automate article
                  writing to attract targeted customers, optimize SEO to rank
                  your website on Google's first page for high-value keywords,
                  and maximize your business returns.
                </p>

                <div>
                  <div className="position-relative search-box-home mx-auto">
                    <span className=" home-search pl-3">
                      <IoIosSearch size={26} />
                    </span>
                    <input
                      placeholder="example.com"
                      type="search"
                      className=" py-3 px-5 col-lg-6 col-md-8 col-sm-12"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                    <span
                      className="search-go white-title"
                      onClick={handleSearch}
                      style={{ cursor: "pointer" }}
                    >
                      Go
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Partner />
        </div>
      </div>
    </>
  );
};

export default BannerFive;
