export const planData = [
  {
    id: "1",
    plan: "Free Forever",
    planDesc:
      "Designed for small and medium enterprises to automate SEO services efficiently",
    amountCurrency: "$",
    amount: "0",
    amounttime: "/mo",
    planpara: "Free but worth it",

    planPoints: [
      {
        title: "AI-Driven Blog Creation and Hassle-Free Posting",
        description: "5 Blogs",
      },
      {
        title: "AI-Powered Keyword Research for Maximum Visibility",
        description: "15 Keyword",
      },
      {
        title: "Smart Social Media Automation for Brand Growth",
        description: "Unlimited campaign",
      },

      {
        title: "Website Performance Testing and Monitoring",
        description: "Unlimited Potential",
      },
      {
        title: "Unified Analytics Dashboard",
        description: "Unlimited Potential",
      },
      {
        title: "24/7 Customer Support",
        description: "Unlimited",
      },
    ],
  },
  {
    id: "2",
    plan: "Standard",
    planDesc:
      "Designed for small and medium enterprises to automate SEO services efficiently",
    amountCurrency: "$",
    amount: "49.99",
    amounttime: "/mo",
    planpara: "Billed monthly",

    planPoints: [
      {
        title: "AI-Driven Blog Creation and Hassle-Free Posting",
        description: " 15 Blogs",
      },
      {
        title: "AI-Powered Keyword Research for Maximum Visibility",
        description: "50 keyword",
      },
      {
        title: "Smart Social Media Automation for Brand Growth",
        description: "Unlimited campaign",
      },

      {
        title: "Website Performance Testing and Monitoring",
        description: "Unlimited Potential",
      },
      {
        title: "Unified Analytics Dashboard",
        description: "Unlimited Potential",
      },
      {
        title: "24/7 Customer Support",
        description: "Unlimited",
      },
    ],
  },
  {
    id: "3",
    plan: "Premium",
    planDesc: "Intended for established businesses and agencies",
    amountCurrency: "$",
    amount: "199.99",
    amounttime: "/mo",
    planpara: "Billed monthly",

    planPoints: [
      {
        title: "AI-Driven Blog Creation and Hassle-Free Posting",
        description: "30 Blogs Monthly",
      },
      {
        title: "AI-Powered Keyword Research for Maximum Visibility",
        description: "Unlimited Recommendations",
      },

      {
        title: "Smart Social Media Automation for Brand Growth",
        description: "Unlimited campaign",
      },
      
      {
        title: "Website Performance Testing and Monitoring",
        description: "Unlimited Potential",
      },

      {
        title: "Unified Analytics Dashboard",
        description: "Unlimited Potential",
      },
      {
        title: "Effortless Video Creation and Instant Auto-Posting",
        description: "120 minutes, Unlimited Videos",
      },
      {
        title: " Seamless Integration with 7000+ Applications",
        description: "Unlimited Potential",
      },
      {
        title: "24/7 Customer Support",
        description: "Unlimited",
      },
    ],
  },
];
